import './jquery-global';
/* global jQuery */
import Rails from '@rails/ujs';

import debounced from 'debounced';
import preserveSidebarScroll from '../preserve_sidebar_scroll';
import TurboFrameErrorRenderer from '../turbo_frame_error_renderer';
import '@hotwired/turbo-rails';
import '../sidebar_slider';
import '../stimulus_setup';
import '../turbo_frame_morph';
import '../stream_actions';

// org hierarchy
import '@nosferatu500/react-sortable-tree/style.css';

if (!window._rails_loaded) {
  Rails.start();
}

if (window.RAILS_ENV === 'development' || window.RAILS_ENV === 'test') {
  // provide feedback to devs when turbo-frame load fails
  TurboFrameErrorRenderer.start();
}

// Preserve sidebar scroll
preserveSidebarScroll();

// TODO: remove this once ajax calls are migrated to fetch
window.ajax = jQuery.ajax;
debounced.initialize({ keyup: { wait: 300 } });

jQuery.ajaxPrefilter((options, _originalOptions, _jqXHR) => {
  const { beforeSend: beforeSendOption } = options;

  options.beforeSend = function beforeSend(xhr) {
    const token = jQuery('meta[name="csrf-token"]').attr('content');
    if (token) { xhr.setRequestHeader('X-CSRF-Token', token); }
    // eslint-disable-next-line prefer-rest-params
    if (beforeSendOption) { return beforeSendOption.apply(this, arguments); }
    return null;
  };
});
