import { StreamActions } from '@hotwired/turbo';

StreamActions.switch_class = function () {
  const scope = document.getElementById(this.getAttribute('scope')) || document;
  const targetSelector = this.getAttribute('target-selector');
  const className = this.getAttribute('class-name');

  scope.querySelector(`.${className}`)?.classList.remove(className);
  scope.querySelector(targetSelector)?.classList.add(className);
};
