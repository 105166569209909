import { StreamActions } from '@hotwired/turbo';

StreamActions.animate_with_class = function () {
  const className = this.getAttribute('class');

  this.targetElements.forEach((e) => {
    e.classList.remove(className);
    window.requestAnimationFrame(() => {
      e.classList.add(className);
    });
  });
};
