// Preserve sidebar scroll height

// if a clicked link contains data-turbo-preserve-scroll, then preserve
// the scroll position of #page-sidebar between navigations

const preserveSidebarScroll = function preserveSidebarScroll() {
  let scrollPosition = window.sessionStorage.getItem('sidebarScrollPosition') || 0;

  // save scroll position (alpha)
  document.addEventListener('DOMContentLoaded', () => {
    document.addEventListener('click', ({ target }) => {
      if (target.hasAttribute('data-turbo-preserve-scroll')) {
        const pageSidebar = document.getElementById('page-sidebar');

        if (pageSidebar) {
          window.sessionStorage.setItem('sidebarScrollPosition', pageSidebar.scrollTop);
        }
      } else {
        window.sessionStorage.removeItem('sidebarScrollPosition');
      }
    });
  }, false);

  // save scroll position (bravo)
  document.addEventListener('turbo:click', ({ target }) => {
    if (target.hasAttribute('data-turbo-preserve-scroll')) {
      const pageSidebar = document.getElementById('page-sidebar');

      if (pageSidebar) {
        scrollPosition = pageSidebar.scrollTop;
      }
    } else {
      // re-set scrollPosition to top
      scrollPosition = 0;
    }
  });

  // when page loads, scroll #page-sidebar, if appropriate
  document.addEventListener('turbo:load', () => {
    if (scrollPosition) {
      document.getElementById('page-sidebar').scrollTo(0, scrollPosition);
      scrollPosition = 0;
    }
  });
};

export default preserveSidebarScroll;
